<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Editar {{ editedItem.title_es }}</span>
      </v-card-title>

      <v-card flat>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.order"
                  label="Orden"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab key="es">
                Español
              </v-tab>
              <v-tab key="en">
                Inglés
              </v-tab>
              <v-tab key="po">
                Portugués
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="es">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.title_es"
                      label="Pregunta ES"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.content_es"
                      label="Respuesta ES"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="en">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.title_en"
                      label="Pregunta EN"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.content_en"
                      label="Respuesta EN"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="po">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.title_po"
                      label="Pregunta PO"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.content_po"
                      label="Respuesta PO"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["visible", "item"],
  data: () => {
    return {
      tab: "es",
      editedItem: {},
      dialog: false
    };
  },
  mounted() {
    this.editedItem = this.item;
    this.dialog = this.visible;
  },
  watch: {
    item: {
      handler(val) {
        this.editedItem = val;
      },
      deep: true
    },
    visible: {
      handler(val) {
        this.dialog = val;
      }
    }
  },
  methods: {
    save() {
      this.$emit("save", this.editedItem);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
