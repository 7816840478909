<template>
  <v-card>
    <v-card-title>
      <FAQDialog
        :visible="dialog"
        :item="editedItem"
        @save="save"
        @close="close"
      />
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >¿Seguro que quieres eliminar la pregunta?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Eliminar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-btn @click="newItem" color="blue-grey" class="ma-2 white--text">
          Nueva FAQ
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="searchableFields"
          v-model="activeFilter"
          item-text="text"
          item-value="value"
          label="Campos"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="filterSearch"
          append-icon="mdi-magnify"
          label="Filtrar"
        ></v-text-field>
      </v-col>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selectedFields"
          :items="allFields"
          label="Columnas"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>
    <v-data-table
      :headers="selectedHeaders"
      :items="faqs"
      :options.sync="options"
      multi-sort
      :server-items-length="totalFaqs"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import FAQDialog from "@/components/mylogy/dialogs/FAQDialog.vue";
export default {
  components: { FAQDialog },
  data() {
    return {
      totalFaqs: 0,
      faqs: [],
      filterSearch: null,
      loading: true,
      activeFilter: "title_es",
      options: {},
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      editedItem: {
        id: "",
        order: "",
        title_es: "",
        content_es: "",
        title_en: "",
        content_en: "",
        title_po: "",
        content_po: ""
      },
      selectedFields: [
        "Orden",
        "Pregunta ES",
        "Pregunta EN",
        "Pregunta PO",
        "Acciones"
      ],
      headers: [
        {
          text: "Orden",
          align: "start",
          sortable: true,
          searchable: true,
          value: "order"
        },
        {
          text: "Pregunta ES",
          value: "title_es",
          sortable: true,
          searchable: true
        },
        {
          text: "Respuesta ES",
          value: "content_es",
          sortable: true,
          searchable: true
        },
        {
          text: "Pregunta EN",
          value: "title_en",
          sortable: true,
          searchable: true
        },
        {
          text: "Respuesta EN",
          value: "content_en",
          sortable: true,
          searchable: true
        },
        {
          text: "Pregunta PO",
          value: "title_po",
          sortable: true,
          searchable: true
        },
        {
          text: "Respuesta PO",
          value: "content_po",
          sortable: true,
          searchable: true
        },
        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    searchableFields() {
      return this.selectedHeaders.filter(h => h.searchable);
    },
    allFields() {
      return this.headers.map(h => h.text);
    },
    selectedHeaders() {
      return this.headers.filter(h => {
        return this.selectedFields.includes(h.text);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    filterSearch: {
      handler() {
        this.getDataFromApi();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    newItem() {
      this.editedItem = Object.assign(
        {},
        {
          id: "",
          order: "",
          title_es: "",
          content_es: "",
          title_en: "",
          content_en: "",
          title_po: "",
          content_po: ""
        }
      );
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.faqs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.faqs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      Strapi.delete(`/faqs/${this.editedItem.id}`)
        .then(() => {
          self.$alertify.success("Muestra eliminada");
        })
        .catch(() => {
          self.$alertify.error("Ha ocurrido un error");
        })
        .then(() => {
          self.closeDelete();
          self.getDataFromApi();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save(data) {
      let self = this;
      if (this.editedItem.id != "") {
        Strapi.put(`/faqs/${this.editedItem.id}`, data)
          .then(() => {
            self.$alertify.success("FAQ actualizada");
          })
          .catch(() => {
            self.$alertify.error("Ha ocurrido un error");
          })
          .then(() => {
            self.close();
            self.getDataFromApi();
          });
      } else {
        delete data.id;
        Strapi.post(`/faqs`, data)
          .then(() => {
            self.$alertify.success("FAQ creada");
          })
          .catch(() => {
            self.$alertify.error("Ha ocurrido un error");
          })
          .then(() => {
            self.close();
            self.getDataFromApi();
          });
      }
    },
    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const _sort = sortBy
        .map(function(e, i) {
          return `${e}:${(sortDesc[i] && "DESC") || "ASC"}`;
        })
        .join(",");
      const qs = require("qs");

      let params = {
        _start: (page - 1) * itemsPerPage,
        _limit: itemsPerPage
      };

      if (sortBy.length > 0) {
        params._sort = _sort;
      }

      if (this.filterSearch) {
        params._where = {
          _or: [
            { FIELD: this.filterSearch },
            { FIELD_contains: this.filterSearch }
          ]
        };
      }

      const query = qs
        .stringify(params, {
          encodeValuesOnly: true
        })
        .replace(/FIELD/g, this.activeFilter);

      Strapi.get(`/faqs?${query}`).then(res => {
        this.faqs = res.data;
        Strapi.get(`/faqs/count?${query}`).then(resCount => {
          this.totalFaqs = resCount.data;
          this.loading = false;
        });
      });
    }
  }
};
</script>
<style lang="scss"></style>
